import React from 'react';
import styled from 'styled-components';

export const SinglePageEmbeddedVideo = ({
  videoSrcURL,
  videoTitle,
  gallery,
}) => (
  <Wrapper>
    <StyledVideo gallery={gallery}>
      <iframe
        src={`https://player.vimeo.com/video/${videoSrcURL}`}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </StyledVideo>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 0px ${(p) => (p.gallery ? 25 : 0)}px;
  margin-left: ${(p) => (p.gallery ? 10 : 0)}px;
  flex: 5;
  min-width: 400px;

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
`;

const StyledVideo = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(p) => (p.gallery ? 95 : 100)}%;
  }

  @media screen and (max-width: 800px) {
    min-width: 80vw;
    height: 60vw;
    padding: 20px 0;
  }
`;
