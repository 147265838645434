import React, { useState } from 'react';
import styled from 'styled-components';

export const Share = ({ url }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleShare = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ShareContainer
      className="shareContainer"
      onClick={toggleShare}
      isOpen={isOpen}
    >
      <p> SHARE </p>
      <div>
        <a
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
        >
          FACEBOOK
        </a>
        <a href={`https://twitter.com/intent/tweet?url=${url}`} target="_blank">
          TWITTER
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
          target="_blank"
        >
          LINKEDIN
        </a>
      </div>
    </ShareContainer>
  );
};

const ShareContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${(p) => (p.isOpen ? 150 : 50)}px;
  transition: 250ms;

  p {
    margin: 25px 0 !important;
    font-family: RalewayBold;
    font-size: 14px;
    cursor: pointer;
  }

  div {
    opacity: ${(p) => (p.isOpen ? 1 : 0)};
    transition: 250ms;
    width: 100%;
  }

  a {
    color: var(--black);
    width: 100%;
    line-height: 15px;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    letter-spacing: 0.02px;
    font-weight: 600;
    display: block;
    margin: 5px 0 15px 0;
    font-family: RalewayBold;
  }
`;
