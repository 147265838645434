import React from 'react';
import { graphql } from 'gatsby';
import { Logo } from '../components/Logo';
import { SEO } from '../components/SEO';
import { JournalSinglePageTemplate } from '../components/JournalSinglePageTemplate';

export default function JournalSinglePage({ data }) {
  const { current } = data.journal.slug;
  return (
    <>
      <SEO title={current} />
      <Logo theme="color" />
      <JournalSinglePageTemplate data={data.journal} />
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    journal: sanityCommercialJournalSinglePages(
      slug: { current: { eq: $slug } }
    ) {
      slug {
        current
      }
      _rawBoldTopText(resolveReferences: { maxDepth: 10 })
      _rawContact(resolveReferences: { maxDepth: 10 })
      _rawVideoText(resolveReferences: { maxDepth: 10 })
      gallery_image {
        asset {
          fluid(maxWidth: 200, maxHeight: 113) {
            ...GatsbySanityImageFluid
          }
        }
      }
      sidebar1
      sidebar2
      sidebar3
      smallVideoDescription
      title
      video
    }
  }
`;
