import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import { SinglePageEmbeddedVideo } from './SinglePageEmbedded';
import { Share } from './Share';
import { MailTo } from './MailTo';

export const JournalSinglePageTemplate = ({ data }) => {
  const {
    _rawBoldTopText,
    _rawContact,
    _rawVideoText,
    gallery_image,
    sidebar1,
    sidebar2,
    sidebar3,
    slug,
    smallVideoDescription,
    title,
    video,
  } = data;
  return (
    <Wrapper>
      <h1> {title} </h1>
      <div className="topTextWrapper">
        <div className="topTextContent">
          <BlockContent blocks={_rawBoldTopText} />
        </div>
        <div className="topTextSidebar">
          <p className="boldTopText"> {sidebar1} </p>
          <p className="boldTopText"> {sidebar2} </p>
          <p className="boldTopText"> {sidebar3} </p>
        </div>
      </div>
      <div className="videoContainer">
        <SinglePageEmbeddedVideo
          videoSrcURL={video}
          videoTitle={`Movie of - ${title}`}
        />
      </div>
      <span className="showreelText"> {smallVideoDescription} </span>
      <BlockContent blocks={_rawVideoText} />
      <div style={{ width: '500px' }}>
        <Img fluid={gallery_image.asset.fluid} alt={title} />
      </div>

      <BlockContent blocks={_rawContact} />

      <MailTo mailTo="mailto:reach@rumblewood.com" />

      <Share
        url={`https://rumblewood.netlify.app/commercial/journal/${slug}&t=${title}`}
        text="FACEBOOK"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;
  margin-left: 10%;
  padding: 25px;
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  width: 580px;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 686px) {
    width: 100%;
    margin: 40px 0;
  }

  .videoContainer {
    width: 100%;
    box-sizing: border-box;
  }

  p {
    font-size: 15px;
    line-height: 21px;
  }

  h1 {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0.04px;
    color: var(--black);
    font-stretch: normal;
    max-width: 450px;
  }

  .boldTopText {
    font-family: RalewayBold;
    font-size: 15px;
    line-height: 21px;
    color: var(--black);
    max-width: 500px;
  }

  .normalText {
    font-family: RalewayRegular;
    font-size: 15px;
    line-height: 21px;
    color: var(--black);
    font-style: normal;
    max-width: 500px;
  }

  span {
    width: 100%;
  }

  .topTextWrapper {
    display: flex;
    flex-wrap: wrap;
    .topTextContent {
      flex: 3;
    }

    .topTextSidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      flex: 1;

      @media screen and (max-width: 868px) {
        display: none;
      }
    }
  }

  .showreelText {
    font-family: RalewayRegular, serif;
    font-size: 10px;
    line-height: 12px;
    color: var(--black);
    margin-top: -25px;
    letter-spacing: 0.2px;

    @media screen and (max-width: 868px) {
      margin-top: 0;
    }
  }
`;
