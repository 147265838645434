import React from 'react';
import styled from 'styled-components';

export const MailTo = ({ mailTo }) => (
  <StyledAnchor href={mailTo} target="_top">
    Get in touch
  </StyledAnchor>
);

const StyledAnchor = styled.a`
  width: 100%;
  color: var(--black);
  font-family: RalewayBold;
  font-size: 14px;
  margin: 25px 0 0 0;
`;
